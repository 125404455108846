import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get() {
    let result = await axios.get(`${API_ROUTES.judges.get}`);

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.judges.save}`, data);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.judges.delete}/${id}`);

    return result;
  },

  async update(id, data) {
    let result = await axios.patch(`${API_ROUTES.judges.update}/${id}`, data);

    return result;
  }
};
